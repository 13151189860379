import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deserialize, serialize } from 'typescript-json-serializer';
import {HttpClientService} from '../../default/http-client.service';
import {AuthResponse} from '../../../model/security/auth-response';
import {User} from '../../../model/user/User';
import {LoginData} from '../../../model/security/login-data';
import {SignupData} from '../../../model/security/signup-data';


@Injectable({
  providedIn: 'root'
})
export class SecurityApi {

  constructor(private http: HttpClientService) { }

  login(data: LoginData): Observable<AuthResponse> {
    return this.http.post('/api/v1/login', serialize(data, true))
      .pipe(
        map(d => deserialize<AuthResponse>(d, AuthResponse))
      );
  }

  asyncLogin(): Observable<User> {
    return this.http.get('/api/v2/async_login')
      .pipe(
        map(data => deserialize<User>(data, User))
      );
  }

  logout() {
    throw new Error('Implement method');
  }

  signup(data: SignupData): Observable<AuthResponse> {
    console.log('run sign-up from SecurityApi');

    return this.http.post('/api/register', data)
      .pipe(
        map((jsonData) => {
          console.log('before serialization',jsonData);
          console.log('run sign-up from pipe');

          return deserialize<AuthResponse>(jsonData, AuthResponse);
          // return jsonData;
        })
      );
  }

  resetPassword(customerId: string, code: string, form): Observable<boolean> {
    return this.http.post(`/api/v1/reset_password/${customerId}/${code}`, form)
      .pipe(map(() => true));
  }



}
