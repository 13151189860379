import {BaseModel} from '../BaseModel';
import {JsonProperty, Serializable} from 'typescript-json-serializer';

@Serializable()
export class User extends BaseModel {

  @JsonProperty()
  id: string;

  @JsonProperty()
  email: string;


}
