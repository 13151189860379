import { NgModule } from '@angular/core';
import {DataServicesModule} from './data/data-services.module';
import {DefaultServicesModule} from './default/default-services.module';
import {HttpServicesModule} from './http/http-services.module';



@NgModule({
  declarations: [],
  imports: [
    DataServicesModule,
    DefaultServicesModule,
    HttpServicesModule
  ]
})
export class ServicesModule { }
