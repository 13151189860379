import {AuthToken} from './auth-token';
import {JsonProperty, Serializable} from 'typescript-json-serializer';
import {User} from '../user/User';


@Serializable()
export class AuthResponse {

  @JsonProperty()
  token: string;

  @JsonProperty({type: User})
  user: User;
}
