import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SecurityService} from './security/security.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [SecurityService]
})
export class DataServicesModule { }
