import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpOptionsType} from '../../model/http/http-options-type';
import {Dictionary} from '../../model/dictionary';
import {HttpClient} from '@angular/common/http';
import {ApiError} from '../../model/http/api-error';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {


  constructor(private http: HttpClient) {
  }

  get<R>(path: string, params?: Dictionary<string> | Dictionary<string[]>): Observable<R> {
    return this.request<R>('GET', path, {params});
  }

  post<R>(path: string, body?: any | null, params?: Dictionary<string> | Dictionary<string[]>): Observable<R> {
    return this.request<R>('POST', path, {body, params});
  }

  put<R>(path: string, body?: any | null, params?: Dictionary<string> | Dictionary<string[]>): Observable<R> {
    return this.request<R>('PUT', path, {body, params});
  }

  patch<R>(path: string, body?: any | null, params?: Dictionary<string> | Dictionary<string[]>): Observable<R> {
    return this.request<R>('PATCH', path, {body, params});
  }

  delete<R>(path: string, params?: Dictionary<string> | Dictionary<string[]>): Observable<R> {
    return this.request<R>('DELETE', path, {params});
  }

  options<R>(path: string, params?: Dictionary<string> | Dictionary<string[]>): Observable<R> {
    return this.request<R>('OPTIONS', path, {params});
  }

  request<R>(method: string, path: string, options?: HttpOptionsType) {
    const url = this.buildUrl(path);

    return this.http.request<R>(method, url, options)
    .pipe(
      map(r =>
        // @ts-ignore
        r.data
      ),
      catchError(err => this.handleError(err))
    );
  }

  private handleError(res: any): Observable<never> {
    const error = new ApiError('Ne pare rău, a apărut o eroare neașteptată. Vă rugăm să reincercati.');

    if (res.status === 401) {
      // todo: prompt for login?
    }

    if (typeof res === 'string') {
      error.message = res;
    } else if (typeof res.error === 'string') {
      error.message = res.error;
    } else if (typeof res.error === 'object') {
      if (typeof res.error.message === 'string') {
        error.message = res.error.message;
      }
      if (typeof res.error.type === 'string') {
        error.type = res.error.type;
      }
    }

    // display error for every other http exception
    if (res.status !== 404 || res.status !== 401) {
      // todo: display a snackbar with error
    }

    return throwError(error);
  }

  private buildUrl(path: string) {
    return environment.host + path;
  }
}
