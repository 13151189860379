import {Injectable} from '@angular/core';
import {SecurityApi} from '../../http/security/security-api';
import {Observable} from 'rxjs';
import {SignupData} from '../../../model/security/signup-data';
import {AuthResponse} from '../../../model/security/auth-response';


@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  constructor(private securityApi: SecurityApi) {
  }


  signup(data: SignupData): Observable<AuthResponse> {
    console.log('run sign-up from SecurityService');
    return this.securityApi.signup(data);

  }


}
