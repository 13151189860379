import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserApiService} from './user/user-api.service';
import {SecurityApi} from './security/security-api';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [UserApiService, SecurityApi]
})
export class HttpServicesModule { }
