import {NgModule} from '@angular/core';
import {HttpClientService} from './http-client.service';
import {HttpClientModule} from '@angular/common/http';


@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
  ],
  providers: [HttpClientService]
})
export class DefaultServicesModule {
}
