import {Injectable} from '@angular/core';
import {HttpClientService} from '../../default/http-client.service';
import {User} from '../../../model/user/User';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(protected http: HttpClientService) {
  }

  getUser(): Observable<User> {
    return this.http.get<User>('/api/get_user');
  }
}
